import SmelliDemo from "../images/SmelliDemo.gif";
import Carcar from "../images/Carcar.gif";
import PosAffir from "../images/PosAffir.gif";
import getawaycrew from "../images/getawaycrew.gif";
import workoutcal from "../images/workoutcal.gif";
import { Container, Row, Col } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projectsfol from "../images/projectsfol.png";

export const MyProjects = () => {
  const projects = [
    {
      title: "Smelli Belli",
      description:
        "Full stack web application of an e-commerce website featuring scent profile quizzes and employee only pages for inventory and adding products. Built with 4 other team members.",
      projectGif: SmelliDemo,
      gitLab: "https://gitlab.com/smelli-belli/smelli-belli",
      stack: "Python | Django | Javascript | React.js | Bootstrap",
    },
    {
      title: "CarCar",
      description:
        "Automobile dealership management where users can view/edit inventory manufacturers, models, and automobiles, view/schedule service appointments and add technicians, and keep track of sales at their dealership.",
      projectGif: Carcar,
      gitLab: "https://gitlab.com/cindymuffin/project-beta",
      stack: "Python | Django | Javascript | React.js | Bootstrap",
    },
    {
      title: "Positive Affirmations Generator",
      description:
        "Minimalistic web application that generates positive affirmations to help you live your best life because sometimes we just need a little reminder to think on the positive side.",
      projectGif: PosAffir,
      gitLab: "https://gitlab.com/cindymuffin/positive-affirmations-generator",
      stack: "Javascript | HTML | CSS",
    },
    {
      title: "Getaway Crew",
      description:
        "Group trip planning web application that helps your group build an intinerary by adding suggested activities, approving/denying suggested activities, and keep track of estimated expenses.",
      projectGif: getawaycrew,
      gitLab: "https://gitlab.com/cindymuffin/getaway-crew",
      stack: "Python | Django | Javascript | React.js | Bootstrap",
    },
    {
      title: "Workout Tracker",
      description:
        "Personal calendar to plan and keep track your workouts. Each added workout can be customized with exercises specifying your target for each exercise and updating what you accomplished. Proud Achievement medals can also be added on days you hit that PR!",
      projectGif: workoutcal,
      gitLab: "https://gitlab.com/cindymuffin/workout-tracker",
      stack: "Python | Django | HTML | CSS",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col>
            <div className="folder-container">
              <a
                href="https://gitlab.com/cindymuffin"
                target="_blank"
                rel="noreferrer"
              >
                <img className="folder" src={projectsfol} alt="projects" />
              </a>
            </div>
            <Row>
              {projects.map((project, index) => {
                return <ProjectCard key={index} {...project} />;
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
