import {
  TbBrandPython,
  TbBrandReactNative,
  TbBrandBootstrap,
  TbBrandCss3,
  TbBrandJavascript,
  TbBrandGit,
  TbBrandHtml5,
  TbBrandDocker,
} from "react-icons/tb";
import { SiDjango, SiPostgresql } from "react-icons/si";
import { Container, Row, Col } from "react-bootstrap";

export const Skills = () => {
  return (
    <section className="skill" id="skills">
      <img alt="skillsbg" />
      <Container>
        <Row>
          <Col>
            <div className="icon">
              <TbBrandJavascript size={"5em"} />
              <h3>Javascript</h3>
            </div>
          </Col>
          <Col>
            <div className="icon">
              <TbBrandPython size={"5em"} />
              <h3>Python</h3>
            </div>
          </Col>
          <Col>
            <div className="icon">
              <TbBrandHtml5 size={"5em"} />
              <h3>HTML</h3>
            </div>
          </Col>
          <Col>
            <div className="icon">
              <TbBrandCss3 size={"5em"} />
              <h3>CSS</h3>
            </div>
          </Col>
          <Col>
            <div className="icon">
              <TbBrandBootstrap size={"5em"} />
              <h3>Bootstrap</h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="icon">
              <TbBrandReactNative size={"5em"} />
              <h3>React.js</h3>
            </div>
          </Col>
          <Col>
            <div className="icon">
              <SiDjango size={"5em"} />
              <h3>Django</h3>
            </div>
          </Col>
          <Col>
            <div className="icon">
              <SiPostgresql size={"5em"} />
              <h3>PostgreSQL</h3>
            </div>
          </Col>
          <Col>
            <div className="icon">
              <TbBrandGit size={"5em"} />
              <h3>Git</h3>
            </div>
          </Col>
          <Col>
            <div className="icon">
              <TbBrandDocker size={"5em"} />
              <h3>Docker</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
