import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import { useState, useEffect, useCallback } from "react";
import HeaderImg from "../images/HeaderImg.png";

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const [text, setText] = useState("");
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const period = 500;

  const tick = useCallback(() => {
    const toRotate = [
      "Software Engineer",
      "Cat Mama",
      "Avid Foodie",
      "Disney Adult",
      "Casual Climber",
      "Occasional Chef",
      "Halloween Enthusiast",
      "Daytime Doodler",
    ];

    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting
      ? fullText.substring(0, text.length - 1)
      : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta((prevDelta) => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setDelta(period);
    } else if (isDeleting && updatedText === "") {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setDelta(300 - Math.random() * 100);
    }
  }, [isDeleting, loopNum, text.length]);

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);
    return () => {
      clearInterval(ticker);
    };
  }, [delta, text, tick]);

  const email = () => {
    window.open("mailto:cindymuffin@gmail.com");
  };

  return (
    <section className="banner" id="home">
      <Container>
        <Row>
          <Col xs={12} md={5} xl={5}>
            <img src={HeaderImg} alt="Header Img" />
          </Col>
          <Col xs={12} md={7} xl={7}>
            <span className="tagline">Hello there!</span>
            <h1>{`{ I'm Cindy }`}</h1>
            <h2 className="wrap">{text}|</h2>
            <p>
              My interest in coding began when I was younger - I dabbled in
              HTML/CSS through customizing layouts for my Xanga and Myspace (Oh
              no! My age is showing..) and I really loved being able to
              personalize my social medias to fit my personality. Even now,
              throughout my coding journey thus far, I've found that I enjoy
              doing front-end work as I like to visually see the work that I am
              creating. I love that working in front-end development allows me
              to be a technical designer where I can not only work on the
              functionality of a web application but also the visual impact.
              While I have found an interest in front-end development, I'm
              looking to improve and devlop my skills overall as a full stack
              developer and am excited to continuously build upon my programming
              knowledge.
            </p>
            <p>
              In my past life, I was a Preschool Teacher working with little
              humans in exploring their interests and helping them navigate
              socializing with others. While I loved working with children and
              seeing them grow, I didn't feel like I, myself, was growing.
              Becoming a software engineer has opened up many opportunities for
              me to grow, learn, and challenge myself and I am stoked to see
              where this journey takes me!
            </p>
            <p>
              <b>
                I am currently open to work - let's talk if you think I may be a
                good fit for you team!
              </b>
            </p>
            <button onClick={email}>
              Get in touch with me <ArrowRightCircle size={25} />
            </button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
