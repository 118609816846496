import { Container, Row } from "react-bootstrap";
import Logo from "../images/Logo.png";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <img src={Logo} alt="logo" />
          <p>Copyright 2022. All rights reserved by Cindy Luu</p>
        </Row>
      </Container>
    </footer>
  );
};
