import { Col } from "react-bootstrap";
import gitlab from "../images/gitlab.png";

export const ProjectCard = ({
  title,
  description,
  projectGif,
  gitLab,
  stack,
}) => {
  return (
    <Col xs={12} xl={4}>
      <main>
        <div
          class="card-project"
          style={{
            backgroundImage: `url(${projectGif})`,
          }}
        >
          <div class="info">
            <h1 class="title">{title}</h1>
            <p class="description">{description}</p>
            <p class="description">{stack}</p>
            <div className="social-icon">
              <a href={gitLab} target="_blank" rel="noreferrer">
                <img src={gitlab} alt="gitlab" />
              </a>
            </div>
          </div>
        </div>
      </main>
    </Col>
  );
};
